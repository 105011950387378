<script>
import _funzioni from "@src/js/msp/funzioni.js";

export default {
    props: {
        'parametro': {
            type: String,
            required: true,
        },
        'format': {
            type: String,
        },
        'aspect': {
            type: String,
        },
        label: {
            type: String,
            default: ""
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        last: {},
        id: {
            default: Date.now()
        },
        type: {
            type: String,
            default: "text"
        },
        classes: {
            type: Object
        },
        placeHolder: {
            type: String
        },
        rules: {
            type: String
        },
        name: {
            type: String
        },
        inputContainerClass: {
            type: String,
            default: "",
        },
        inputClass: {
            type: String
        },
        dataSource: {
            type: Array
        },
        dataTextField: {
            type: String
        },
        dataValueField: {
            type: String
        },
        value: {},
        column: {
            type: String
        }
    },
  watch: {
    value: {
        handler(val) {
            this.setHasValue(val);
            this.content = val;
            if (val) {
                this.isChanged = true;
            } else {
                this.isChanged = false;
            }
        },
        deep: true
    }
  },
  data() {
      return {
          hasValue: false,
          isChanged: false,
          content: null,
      };
  },
  computed: {
      classItem: function () {
          let c = this.inputContainerClass;
          if (!this.hasValue) {
              c += " empty";
          }
          return c;
      },
  },
  methods: {
      removeValue: function () {
          this.handleInput(null);
      },
      setHasValue: function (test) {
          this.hasValue = (test !== null) ? true : false;
      },
      formatLast: function (last) {
          if (!last) return "";
          const date = _funzioni.formatDate(last.date);
          const label = (this.label) ? this.label : "";
          return `${last.value} ${label} <span class="data">(${date})</span>`;
      },
        resetContent: function () {
            if (this.type === 'slider') {
            this.content = (this.realMin + this.realMax) /2
            } else if (this.type === 'text') {
                this.content = "";
            } else if (this.type === 'number') {
                this.content = 0;
            } 
        },
      handleInput(value) {
          this.setHasValue(value);
          if (!this.hasValue) {
              this.resetContent();
          }
          this.isChanged = true;
          const payload = {
              value: value,
              column: this.column,
          }
          this.$emit("input", payload);
      }
  }
};
</script>
