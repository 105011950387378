<template>
    <v-card class="misurazione-item mb-2">
        <v-card-title class="mb-0 pb-0">{{parametro.nome}}
            <template v-if="hasInfo">
                <v-spacer></v-spacer>
                <v-btn @click="showIstruzioni = !showIstruzioni" text >
                    <v-icon>mdi-information-outline</v-icon>
                </v-btn>
</template>
</v-card-title>
<v-card-text>
<div v-if="hasInfo" class="misurazione-item__istruzioni" v-show="showIstruzioni">
<p v-if="parametro.descrizione"><strong v-text="parametro.descrizione"></strong></p>
<div v-if="parametro.istruzioni" v-html="parametro.istruzioni"> </div>
</div>
<component
v-for="(valore, column) in parametro.valori"
:key = "column"
:column = "column"
:parametro = "parametro.codice"
:last = "initLast(column)"
:value="initValue(column)"
@input="onChange"
v-bind:is="bestInput(params(column).type)"
v-bind="params(column)"
></component>
</v-card-text>
</v-card>

</template>

<script>
import MspSlider from "./Slider.vue"
import MspSwitch from "./Switch.vue"
import MspRadio from "./Radio.vue"
import MspInputText from "./InputText.vue"
import MspInputNumber from "./InputNumber.vue"
const component = {
    components: {
        MspSlider,
        MspSwitch,
        MspRadio,
        MspInputText,
        MspInputNumber,
    },
    props: { 
        'last': {},
        'parametro': {
            type: Object,
            default: null,
        },
        'atleta_id': {
            type: Number,
            default: null,
        },
        'misurazione': {
            type: Object,
            default: null,
        },
    },
    computed: {
        hasInfo: function () {
            if (!this.parametro) return false;
            return this.parametro.descrizione || this.parametro.istruzioni;
        },
    },
    mounted: function () {
    },
    data() {
        return {
            showIstruzioni: false,
            valori: {},
        }
    },
    watch: {
    },
    methods: {
        bestInput(type) {
            if (type == "slider") return MspSlider;
            if (type == "text") return MspInputText;
            if (type == "number") return MspInputNumber;
            if (type == "switch") return MspSwitch;
            if (type == "radio") return MspRadio;
            return MspInputText;
        },
        initValue: function (column) {
            return this.initValueByColumn(this.misurazione, column);
        },
        initLast: function (column) {
            if (!this.last) return null;
            const last = {
                value: this.initValueByColumn(this.last, column),
                date: this.last.data,
            }
            return last;
        },
        initValueByColumn: function (misurazione,column) {
            if (!(misurazione && misurazione.valori)) return null;
            const value = misurazione.valori[column];
            this.valori[column] = value;
            return value;
        },
        params: function (column) {
            if (!(this.parametro && this.parametro.valori)) return null;
            const params = this.parametro.valori[column][1];
            return params;
        },
        onChange: function ({ column, value }) {
            this.valori[column] = value;
            const payload = {
                parametro: this.parametro.codice,
                valori: this.valori,
            };
            this.$emit('change', payload);
        },


    },
}
export default component;
</script>

<style lang="scss">
.misurazione-item {
    &__istruzioni {
        text-align: left;
    }
    .last {
        text-align: left;
        .data {
            font-size: 0.8em;
        }
    }
}
</style>
