<template>
     <div :class="classItem" class="msp-misurazione-slider" :style="styleSlider">
         <span v-if="hasValue" class="msp-misurazione-value">
             <span >{{renderContent(content,aspect,{min:min,max:max,options:options})}}</span>
             <v-btn plain @click="removeValue"><v-icon>mdi-delete</v-icon></v-btn>
         </span>
        <v-slider
            v-model="content"
            thumb-label
            :hide-details="true"
            @change="handleInput"
            :max="realMax"
            :min="realMin"
            :step="step"
            :thumb-color="colorFromValue"
            :tick-labels="tickLabels"
            :tick-size="0"
        >
        </v-slider>
    </div>
</template>
<script>
import Commons from "./Commons.vue";
export default {
    extends: Commons,
    props: {
        'min': {
            type: Number,
        },
        'max': {
            type: Number,
        },
        'step': {
            type: Number,
            default: 1,
        },
        'options': {
            type: Array,
            default: () => [
                {'label': 'basso'},
                {'label': 'normale'},
                {'label': 'top'},
            ],
        },
    },
    data() {
        return {
        }
    },
    computed: {


        tickLabels: function () {
            let tl
            if (!this.hasOptions) {
                tl = [];
            } else {
                const steps = this.realMax - this.realMin + 1; // 1 perché assumo che realMin sia 1
                const labels = this.options.map(el=>el.label);
                tl = Array(steps);
                tl.fill(null);
                labels.forEach((l,i)=>{
                    let pos = Math.round(i * (steps - 1) / (labels.length - 1));
                    tl[pos] = l;
                });
            }

            return tl;
        },

        styleSlider: function () {
            let style;
            if (!this.hasOptions) return "";

            let colors = this.options.map(el=>el.color).filter(el=>el);
            if (!(colors && colors.length)) return "";


            const stringColors = colors.join(", ");
            style = `--bg-slider: linear-gradient(90deg, ${stringColors});`

            return style;
        },

        realMax: function () {
            let max;
            if (this.max !== undefined) {
                max = this.max;
            } else if (this.hasOptions) {
                max = this.options.length;
            }
            return max;
        },
        realMin: function () {
            let min;
            if (this.min !== undefined) {
                min = this.min;
            }
            if (this.hasOptions) {
                min = 1;
            }
            return min;
        },
        hasOptions: function () {
            return this.options && this.options.length;
        },
        colorFromValue: function () {
            if (!this.isChanged) {
                return "#fff6 !important";
            }
            return "var(--col-grigio-scuro)";
        },
    },
    mounted: function () {
        this.resetContent();
    },
    watch: {
        isChanged: function (nv) {
            if (!nv) {
                this.resetContent();
            }
        },
    },
    methods: {
      renderContent: function (valore, aspect, params) {
          if (!aspect) return valore;
          if (aspect === 'percentage') {
              let perc = Math.round(valore * 100 / params.max);
              return perc + "%";
          }
          if (aspect === 'label') {
              const option = params.options.find(el=>el.value==valore);
              if (!option) return valore;
              return option.label;
          }
          if (aspect === 'true') {
              const label = params.true;
              return label;
          }

          return valore;
      },
    },
};
</script>
<style  lang="scss">
.msp-misurazione-slider {
    container-type: inline-size;
    container-name: msp-misurazione-slider;

    --size: 10px;
    --thumb-size: 26px;
    --thumb-bg-color: #fff;
    --thumb-border-color: #000;
    --thumb-border-radius: 2px;
    --bg-slider: linear-gradient(90deg, #c00, #0c0);
    padding-bottom: calc(var(--size)/2);


        /* nascondi tick e label intermedie */
        .v-slider__tick {
            display: none !important;
        }
        .v-slider__tick:first-child,
        .v-slider__tick:last-child {
            display: block !important;
        }
    .msp-misurazione-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &.empty {
        filter: opacity(0.5) grayscale(1);
    }
    .v-slider {
        .v-slider__tick-label {
            top: calc(var(--size)/1.2) !important;
            color: var(--col-grigio);
        }
        .v-slider__track-container {
            height: var(--size);
            border-radius: 10px;
            overflow: hidden;
            background: var(--bg-slider);
        }
        .v-slider__thumb {
            width: var(--thumb-size);
            height: var(--thumb-size);
            left: calc(-0.5 * var(--thumb-size));
        }
        .v-slider__track-fill {
            background: #fff0 !important;
        }
        .v-slider__track-background {
            background: #fff0 !important;
        }
        .v-slider__thumb {
            background: var(--thumb-bg-color) !important;
            border: var(--thumb-border-radius) solid var(--thumb-border-color) !important;
        }
        .v-slider__thumb-label {
            // background-color: red !important;
        }
    }

    &__labels {
        display: flex;
        justify-content: stretch;
        text-align: center;
    }
    &__label {
        flex: 1;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
        }
    }
}
</style>
