<template>
    <div class="msp-aggiungi-misurazione msp-fixed-container" >
        <v-app-bar class="msp-fixed-header">
            <v-btn icon @click="dismissModal()">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-toolbar-title>
                <div class="test-titolo">
                    <h2>Misurazioni</h2>
                </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-show="canSave" icon @click="dismissModal()">
                <v-icon>mdi-check-bold</v-icon>
            </v-btn>

        </v-app-bar>

        <v-container class="msp-fixed-content">
            <div class="header">
                <div class="data">
                    <v-dialog
                        ref="dialog"
                        v-model="editing.data"
                        :return-value.sync="data"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="formatCalendarDay(data)"
                                label="Data"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="data"
                            scrollable
                            :first-day-of-week="1"
                            locale="it-it"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="editing.data = false"
                            >
                                Annulla
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(data)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </div>
                
                <div class="buttons">
                        <v-btn icon @click.stop="showOptions = !showOptions">
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </div>
                    



                     <v-dialog
      v-model="showOptions"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
        >
          <v-btn
            icon
            @click="showOptions = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Preferenze</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>

                            <div class="row-ricordamelo">
                                <span class='v-input'>Ricordamelo:</span>
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="btn-interval"
                                    >
                                        <span v-html="labelInterval"/>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(item, index) in intervalOptions"
                                        :key="index"
                                        @click="selectInterval(item.value)"
                                    >
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                        </v-menu>
                            </div>
                        <v-checkbox
                            v-for="parametro in parametri" :key="parametro.codice"
                            @change="updateOptions"
                            :hide-details="true"
                            v-model="options"
                            :label="parametro.nome"
                            :value="parametro.codice"
                        ></v-checkbox>
    
    
                </v-card-text>
      </v-card>
    </v-dialog>

            </div>
            <misurazione-item v-for="parametro in filteredParametri" 
                :key="parametro.codice"
                :parametro="parametro"
                :atleta_id="atletaId"
                :last="last[parametro.codice]"
                :misurazione="findMisurazione(parametro.codice)"
                @change="onChange"
            ></misurazione-item>

            <v-alert
                class="alert-toast"
                transition="scale-transition"

                :value="alertVisible" type="success">
                Misurazione salvata con successo.
            </v-alert>
        </v-container>


    </div>
</template>

<script>
import api  from "@mixins/api.js";
import _utente from "@mixins/utente.js";
import _misurazioni from "@mixins/misurazioni.js"
import { env } from "@mixins/environment.js"
import MisurazioneItem from "@components/misurazione/Item.vue"
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import { mapGetters } from 'vuex'
const component = {
    components: {
        MisurazioneItem,
    },
    mixins: [
        allenamentoCalcoli,
    ],
    props: [
    ],
    computed: {
        labelInterval: function () {
            let label;
            if (!this.interval) {
                label = "non<br>ricordarlo";
            } else if (this.interval == 1) {
                label = "<span>Ogni</span><br> <strong>giorno</strong>";
            } else {
                label = `<span>Ogni</span><br> <strong>${this.interval} gg</strong>`;
            }
            return label;
        },
        ...mapGetters('calendario', [
            'atletaId',
        ]),
        filteredParametri: function () {
            const filtered = this.parametri.filter(el=>this.options.indexOf(el.codice) !== -1);
            return filtered;
        },
    },
    data() {
        return {
            canSave: false,
            last: {},
            interval:0,
            intervalOptions: [],
            showOptions: false,
            alertVisible: false,
            url: "",
            editing: {
                data: false,
                titolo: false,
            },
            data: null,
            options: [],
            parametri: [],
            misurazioni: [],
            autovalutazione: {id: 0},
        }
    },
    watch: {
        data: function (nv) {
            if (nv) {
                this.loadMisurazioni(nv);
            }
        },
    },
    mounted: function () {
        this.init();
    },

    methods: {
        init: function () {
            this.prepareInterval();
            this.loadParametri();
            this.data = this.date2string(new Date());
            if (this.$route.query.filtered_options) {
                this.setFixedOptions();
            } else {
                this.loadOptions();
            }
        },
        makeIntervalOptions: function (value) {
            let title;
            if (value) {
            let suffix = (value == 1) ? "giorno" : "giorni";
             title = value + " " + suffix;
            } else {
                title = "mai";
            }
            return {
                title: title,
                value: value,
            }
        },
        setIntervalOptions: function () {
            this.loadIntervalOptions()
                .then((result)=>{
                    let valori = result;
                    let options = valori.map(this.makeIntervalOptions);
                    this.intervalOptions = options;

                });
        },
      selectInterval: function (interval) {
          this.interval = interval;
          this.setIntervalAlertAutovalutazione(interval);
        },

        loadIntervalOptions: function () {
            let url = env.api_base_url+"/api/v2/autovalutazione_atleta.php?enum-interval-alert";
            return new Promise((resolve,reject)=>{
                api(url, "GET", null)
                    .then((result)=>{
                        if (result.success) {
                            resolve(result.data);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((error)=>{
                        reject(error);
                    })
            });


        },

        getIntervalAlertAutovalutazione: function () {
            let url = env.api_base_url+"/api/v2/autovalutazione_atleta.php?interval-alert";
            return new Promise((resolve,reject)=>{
                api(url, "GET", null)
                    .then((result)=>{
                        if (result.success) {
                            resolve(result.data);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((error)=>{
                        reject(error);
                    })
            });


        },
        setIntervalAlertAutovalutazione: function (interval) {
            let url = env.api_base_url+"/api/v2/autovalutazione_atleta.php?interval-alert";
            let payload = {
                value: interval
            };
            return new Promise((resolve,reject)=>{
                api(url, "POST", payload)
                    .then(result=>{
                        if (result.success) {
                            resolve(result.data);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((error)=>{
                        reject(error);
                    })
            });
        },
        prepareInterval: function () {
            this.setIntervalOptions();
            this.getIntervalAlertAutovalutazione()
                .then(
                    (result) => {
                        this.interval = result;
                    }
                );
        },
        showAlert: function () {
            this.alertVisible = true;
            setTimeout(()=>{this.alertVisible = false;}, 3000);
        },

        onChange: function ({parametro, valori}) {
            this.canSave = true;
            const data = this.data;
            this.saveMisurazione(data, parametro, valori);
        },
        resetDialog: function () {
            this.data = null;
        },

        dismissModal () {
            this.resetDialog();
            this.$store.dispatch("calendario/setDialog", false);
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        },

        loadParametri: async function () {
            const parametri = await _misurazioni.loadParametri();
            if (parametri) {
                this.parametri = parametri;
                this.loadLastFromParametri();
            }
            return parametri;
        },
        loadLastFromParametri: function () {
            this.parametri.forEach(async (parametro)=>{
                let last = await this.loadLast(parametro);
                this.last[parametro.codice] = last;
            });
        },
         
        loadLast: async function (parametro) {
            return _misurazioni.loadLast(this.atletaId, parametro);
        },

        saveMisurazione: async function (data, parametro, valori) {
            let hasValue = _misurazioni.valoriNotEmpty(valori);

            if (hasValue) {
                await _misurazioni.saveMisurazione(data, parametro, valori);
            } else {
                await _misurazioni.deleteMisurazione(data, parametro);
            }

            const range = {
                start: this.data,
                end: this.data,
            }
            this.$store.dispatch("calendario/loadMisurazioni", range);
        },

        findMisurazione: function (parametro) {
            if (!this.misurazioni) return null;
            let misurazione = this.misurazioni.find(el=>el.parametro===parametro);
            return misurazione;
        },
        loadMisurazioni: async function (data) {
            this.canSave = false;
            const misurazioni = await _misurazioni.loadMisurazioni(this.atletaId, data, data);
            if (misurazioni) {
                this.misurazioni = misurazioni;
            } else {
                this.misurazioni = [];
            }
        },

        updateOptions: function () {
            _utente.setOption('misurazioni', this.options);
        },
        setFixedOptions: async function () {
            this.options = this.$route.query.filtered_options;
        },
        loadOptions: async function () {
            let options = await _utente.getOption('misurazioni');
            if (!(options && options.length)) {
                options = [];
            }
            this.options = options;
        },
    }
}
export default component;
</script>

<style lang="scss">
.row-ricordamelo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}
.msp-aggiungi-misurazione {
    .header {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 3px;
    }
    .row {
        flex-direction: column;
    }
    .alert-toast {
        position: fixed;
        bottom: 20px;
        left: 50%;
        width: 90%;
        transform: translate3d(-50%, 0, 0);
    }
    .options-container.active {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: #fff;
        padding: 10px;

    }
}
</style>
